<template>
  <b-modal
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer=""
    size="md"
    :title="$t('payments.reasonCancel.confirmation')"
    @show="verifyIsMexico"
    @hidden="handleClose"
  >
    <b-container class="p-0">
      <h4>{{ $t('payments.reasonCancel.cancellationTitle') }}:</h4>
      <b-form-row>
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            :options="options"
            name="cancelOptions"
            class="pl-2"
            stacked
          />
        </b-form-group>
      </b-form-row>
      
      <div class="float-right pt-2">
        <b-button
          class="ml-2"
          data-test="modal-transaction__btn-save"
          @click="$emit('closeModalCancelTransaction')"
        >
          {{ $t('payments.buttons.cancel') }}
        </b-button>

        <b-button
          variant="primary"
          class="ml-2"
          data-test="modal-transaction__btn-save"
          :disabled="!selected"
          @click="cancelTransaction()"
        >
          {{ $t('payments.buttons.confirm') }}
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>


<script>
import TRANSACTION_CANCEL from '@graphql/transaction/mutations/cancel.gql';
import { makeMutation } from "@graphql/middleware";
import { isMexico } from '@utils/helper-region';
import { mapGetters } from 'vuex'
export default {
  name: 'ModalCancelTransaction',
  data () {
    return {
      selected: '',
      options: [
        { text: this.$t('payments.reasonCancel.planExchange'), value: this.$t('payments.reasonCancel.planExchange') },
        { text: this.$t('payments.reasonCancel.incorrectValue'), value: this.$t('payments.reasonCancel.incorrectValue') },
        { text: this.$t('payments.reasonCancel.exchangePaymentDate'), value: this.$t('payments.reasonCancel.exchangePaymentDate') },
        { text: this.$t('payments.reasonCancel.allowanceSecurityDeposit'), value: this.$t('payments.reasonCancel.allowanceSecurityDeposit') },
        { text: this.$t('payments.reasonCancel.billingDuplicity'), value: this.$t('payments.reasonCancel.billingDuplicity') },
        { text: this.$t('payments.reasonCancel.postClosureCharge'), value: this.$t('payments.reasonCancel.postClosureCharge') },
        { text: this.$t('payments.reasonCancel.discountGrant'), value: this.$t('payments.reasonCancel.discountGrant') },
        { text: this.$t('payments.reasonCancel.sinister'), value: this.$t('payments.reasonCancel.sinister') },
        { text: this.$t('payments.reasonCancel.withdrawalOfLease'), value: this.$t('payments.reasonCancel.withdrawalOfLease') },
        { text: this.$t('payments.reasonCancel.securityDeposit'), value: this.$t('payments.reasonCancel.securityDeposit') },
        { text: this.$t('payments.reasonCancel.bookingCancel'), value: this.$t('payments.reasonCancel.bookingCancel') },
        { text: this.$t('payments.reasonCancel.insuranceTriggered'), value: this.$t('payments.reasonCancel.insuranceTriggered') },
        { text: this.$t('payments.reasonCancel.checkedCarRepair'), value: this.$t('payments.reasonCancel.checkedCarRepair') }
      ]
    }
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    modalData: {
      type: Object
    }
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      }
    },
  },
  methods: {
    verifyIsMexico () {
      if(isMexico()) {
        this.options.push({ 
          text: this.$t('payments.reasonCancel.expiredTicket'),
          value: this.$t('payments.reasonCancel.expiredTicket')
        })
      }
    },
    cancelTransaction () {
      if (!this.selected) {
       this.$swal({
          type: 'error',
          title: this.$t('payments.reasonCancel.erroCancelTransaction'),
          showConfirmButton: false,
          timer: 2500,
        });
        return
      }
      const internalComents = this.selected;

      makeMutation(
          TRANSACTION_CANCEL,
          {
            id: this.modalData.id,
            driver: this.modalData.driver,
            invoice: this.modalData.invoice,
            payment_method_token: this.modalData.payment_method_token,
            internal_comments: `[ ${this.$store.getters['user/attributes'].email}
            @${this.$moment().format('DD/MM/YYYY HH:mm')} ] -- ${internalComents}\n\n`,
          },
        )
        .then(() => {
          this.$emit('successCancelTransaction')
          this.$emit('closeModalCancelTransaction')
        })
        .catch((error) => {
          this.$emit('errorCancelTransaction')
        })
    },
    handleClose() {
      this.$emit('change', false);
    }
  }
}
</script>